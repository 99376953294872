export default [
  {
    text: "Профиль",
    link: { name: "SocialActiveProfile" },
    key: "profile",
    disabled: false,
  },
  {
    text: "Персональные данные",
    link: { name: "SocialActivePersonalData" },
    key: "personal",
    disabled: false,
  },
  {
    text: "Успеваемость",
    link: { name: "SocialActivePerformance" },
    key: "performance",
    disabled: false,
  },
  {
    text: "Мои достижения",
    link: { name: "SocialActiveAchievements" },
    key: "achievements",
    disabled: false,
  },
];
